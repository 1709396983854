<template>
  <section class="cont cash">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <div class="box">
        <div>
          可提现余额 ￥{{ accountWithdrawbalance }}
          <el-button type="primary" size="mini" @click="isShow = true" class="m-left-10" :disabled="accountWithdrawbalance < 2">提现</el-button>
        </div>
        <div>冻结金额 ￥{{ frozenAmount }}</div>
        <div>累计已提现 ￥{{ cumulativelyWithdrawn }}</div>
      </div>
    </el-row>

    <el-row class="content-box m-top-20">
      <el-row class="table-box">
        <el-tabs v-model="activeName">
          <el-tab-pane label="提现记录" name="cash">
            <el-table :data="tableData" border fit :stripe="true">
              <el-table-column type="index" width="100" label="序号"></el-table-column>
              <el-table-column prop="createTime" label="提现时间"></el-table-column>
              <el-table-column label="提现账户">
                <template slot-scope="scope">{{ scope.row.companyId }}(银行账户)</template>
              </el-table-column>
              <el-table-column label="提现金额">
                <template slot-scope="scope">￥{{ scope.row.withdrawalAmount }}</template>
              </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  {{ scope.row.withdrawalState == 0 ? '提现失败' : scope.row.withdrawalState == 1 ? '提现成功' : '提现中' }}
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination v-show="total" :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-row>

    <!-- 提现弹窗 -->
    <el-dialog title="提现" :visible.sync="isShow" width="30%">
      <el-alert :title="`最多可提现金额：￥${accountWithdrawbalance}`" type="info" class="info" :closable="false"></el-alert>
      <label class="tips">输入提现金额</label>
      <el-input-number v-model="num" :min="2" :max="accountWithdrawbalance" size="mini"></el-input-number>
      <div class="m-top-10 c-red">注意：每次提现手续费为1元，提现金额中扣除,最低可提现2元</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取消</el-button>
        <el-button type="primary" @click="Withdraw">提现</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import { getAccount, getRecordPage, withdrawals } from '@/api/system/ysManagement'
  export default {
    data() {
      return {
        crumbs: new Map([['业务中台'], ['提现管理']]),
        activeName: 'cash',
        tableData: [],
        isShow: false, // 弹窗显示
        accountWithdrawbalance: 0 ,  // 可提现余额
        frozenAmount: 0,            // 冻结金额
        cumulativelyWithdrawn : 0 ,  // 累计已提现
        num: 2,
        total: 0,      // 用户列表总条目数
        page: 1,       // 当前页 默认第一页
        limit: 10,     // 每页显示数
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    mounted() {
      this.getData();
      this.account();
    },
    methods: {
      // 获取提现记录
      getData() {
        let data = {
          hotelId: this.hotelInfo.id,
          limit: this.limit,
          page: this.page,
        }
        getRecordPage(data).then(({total,success,records}) => {
          this.total = total
          if (success) {
            this.tableData = records
          }
        })
      },
      // 商户账户
      account() {
        getAccount(this.hotelInfo.id).then(({success,data}) => {
          if (success) {
            this.accountWithdrawbalance = data.accountWithdrawbalance
            this.frozenAmount = data.frozenAmount
            this.cumulativelyWithdrawn = data.cumulativelyWithdrawn
          }
        })
      },
      // 提现
      Withdraw() {
        let data = {
          companyId: this.tableData[0].companyId,
          hotelId: this.hotelInfo.id,
          withdrawalAmount: this.num
        }
        withdrawals(data).then(({success,data})=>{
          if (success&&data) {
            this.$message({showClose: true,message: '提现成功!',type: 'success'});
            this.isShow = false
            this.num = 2
            this.getData();
            this.account();
          }
        })
      },
      // 改变每页数
      pageChange(num) {
        this.limit = num;
        this.getData();
      },
      // 改变当前页
      handlePaging(num) {
        this.page = num;
        this.getData();
      },
    },
  }
</script>

<style lang="scss" scoped>
  .cash {
    .box {
      display: flex;
      justify-content: space-between;
    }
    .info {
      width: 576px;
      margin: -30px -20px 20px;
    }
    .tips {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      margin-right: 20px;
    }
  }
</style>
